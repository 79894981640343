import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

class ScatterPlot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // To avoid unnecessary update keep all options in the state.
      chartOptions: {
        chart: {
          type: "scatter",
          zoomType: "xy",
          marginBottom: 100,
        },
        title: {
          text: props.children.title,
        },
        // subtitle: {
        //     text: 'Source: Heinz  2003'
        // },
        xAxis: {
          title: {
            enabled: true,
            text: props.children.Xaxis,
          },
          startOnTick: true,
          endOnTick: true,
          showLastLabel: true,
        },
        yAxis: {
          title: {
            text: props.children.Yaxis,
          },
        },
        legend: {
          align: "center",
          verticalAlign: "bottom",
          x: 0,
          y: 0,
          //floating: true,
          backgroundColor: Highcharts.defaultOptions.chart.backgroundColor,
          //borderWidth: 1,
        },
        plotOptions: {
          scatter: {
            marker: {
              radius: 5,
              states: {
                hover: {
                  enabled: true,
                  lineColor: "rgb(100,100,100)",
                },
              },
            },
            states: {
              hover: {
                marker: {
                  enabled: false,
                },
              },
            },
            tooltip: {
              headerFormat: "<b>{series.name}</b><br>",
              pointFormat: "{point.x:,.0f}, {point.y}",
            },
          },
        },
        series: props.children.series,
      },
      hoverData: null,
    };
    Highcharts.setOptions({
      lang: {
        thousandsSep: ','
      }
    })
  }
  

  render() {
    const { chartOptions, hoverData } = this.state;

    return (
      <div>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </div>
    );
  }
}

export default ScatterPlot;
