import "./tailwind.output.css";
import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { HelmetProvider } from "react-helmet-async";
import Landing from "./pages/Landing";
import Card from "./pages/Card";
import Navbar from "./components/Navbar";
import Terms from "./pages/Terms";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Footer from "./components/Footer";
import Privacy from "./pages/Privacy";
import { Account, AccountContext } from "./auth/Account";
import How from "./pages/How";
import Contact from "./pages/Contact";
import "react-toastify/dist/ReactToastify.css";
import About from "./pages/About";
import Forgot from "./pages/Forgot";
import Profile from "./pages/Profile";

function App() {
  return (
    <HelmetProvider>
      <Account>
        <ToastContainer />
        <Router>
          <Navbar />
          <Switch>
            <Route path='/login' component={Login} />
            <Route path='/register' component={Register} />
            <Route exact path='/' component={Landing} />
            <Route path='/card/:name' component={Card} />
            <Route exact path='/how' component={How} />
            <Route path='/about' component={About} />
            <Route path='/terms' component={Terms} />
            <Route path='/privacy' component={Privacy} />
            <Route path='/contact' component={Contact} />
            <Route path='/forgot' component={Forgot} />
            <PrivateRoute path='/profile'>
              <Profile />
            </PrivateRoute>
            <Route path='*'>
              <Redirect to='/' />
            </Route>
          </Switch>
          <Footer />
        </Router>
      </Account>
    </HelmetProvider>
  );
}

function PrivateRoute({ children, ...rest }) {
  const { authState } = useContext(AccountContext);

 
  //   useEffect(() => {
  //     const fetchData = async () => {
  //       try{
  //         await getSession();
  //         setIsAuthenticated(authState.isAuthenticated);
  //         console.log(isAuthenticated)
  //         setLoading(false);
  //       }
  //       catch(e){}

  //     };
  //     fetchData();
  // }, []);

  return (
    <Route
      {...rest}
      render={() => (
        authState.isAuthenticated === true ? (
          children
        ) : (
          <Redirect to='/login' />
        )
      )}
    />
  );
}

export default App;
