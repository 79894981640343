import React from "react";
import { Helmet } from "react-helmet-async";
import aboutImage from "../assets/Graphic-01.svg";

const About = () => {
  return (
    <>
      <Helmet>
        <title>{"About | CreditOdds"}</title>
        <meta name='description' content='About CreditOdds' />
      </Helmet>

      <div className='bg-white overflow-hidden'>
        <div className='relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8'>
          <div className='hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen' />
          <div className='mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none'>
            <div>
              <h2 className='text-base text-indigo-600 font-semibold tracking-wide uppercase'>
                About
              </h2>
              <h3 className='mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
                Can I get this card?
              </h3>
            </div>
          </div>
          <div className='mt-8 lg:grid lg:grid-cols-2 lg:gap-8'>
            <div className='relative lg:row-start-1 lg:col-start-2'>
              <svg
                className='hidden lg:block absolute top-0 right-0 -mt-20 -mr-20'
                width={404}
                height={384}
                fill='none'
                viewBox='0 0 404 384'
                aria-hidden='true'
              >
                <defs>
                  <pattern
                    id='de316486-4a29-4312-bdfc-fbce2132a2c1'
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits='userSpaceOnUse'
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className='text-gray-200'
                      fill='currentColor'
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill='url(#de316486-4a29-4312-bdfc-fbce2132a2c1)'
                />
              </svg>
              <div className='relative text-base mx-auto max-w-prose lg:max-w-none'>
                <figure>
                  <div className='aspect-w-12 aspect-h-7 lg:aspect-none'>
                    <img
                      className='rounded-lg shadow-lg object-cover object-center'
                      src={aboutImage}
                      alt='CreditOdds Computer and Credit Card'
                      width={1184}
                      height={1376}
                    />
                  </div>
                </figure>
              </div>
            </div>
            <div className='mt-8 lg:mt-0'>
              <div className='text-base max-w-prose mx-auto lg:max-w-none'>
                <p className='text-gray-500'>
                  My girlfriend asked me that while scrolling through her bank's
                  credit card selection one evening. We'd just finished going
                  over an introduction to credit card rewards and she was eager
                  to get her free flight to the Maldives.
                </p>
              </div>
              <div className='mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1'>
                <p>
                  She gave me her credit score, which was decent, and I told her
                  to apply. A few minutes later her application was complete and
                  she hit submit. REJECTED. Gentleman, guess who she was upset
                  with? (Hint: It wasn't her bank)
                </p>
                <p>
                  I've been working in consumer banking for a little over 2
                  years and people always ask me to "look into their situation."
                  To be honest, I don't even know where I would start if I
                  needed to find the person in charge of applications here. I
                  work on the technology side and I do know that the approval
                  process is almost completely automated. The bank's system
                  takes everything you provide, pulls your credit history, and
                  makes a decision based on select criteria. Unfortunately for
                  everyone, these approval metrics aren't on the application
                  page.
                </p>
                <blockquote>
                  <p>
                    As an example, if a bank decides that nobody with a FICO
                    credit score under 550 should be approved for their card
                    they'll still let those people apply.
                  </p>
                </blockquote>
                <p>
                  While similar to college admissions, there are special cases,
                  the theory behind CreditOdds is that aggregate data will
                  outline the bank's approval criteria for each card.
                </p>
                <h3>Why it matters to my girlfriend and you?</h3>
                <p>
                  My girlfriend was obviously upset that she didn't get the
                  card, but she also knew from our earlier discussion that she
                  had just received a "hard pull" on her credit. In short, when
                  banks collect your credit history from a credit bureau that
                  inquiry lowers your credit score. That's because these hard
                  inquiries remain on your credit report for a few years and too
                  many will make you appear as a high risk to lenders. It also
                  means that immediately following a rejection isn't the best
                  time to apply for another card.
                </p>
                <h3>Final note</h3>
                <p>
                  I'm only scratching the surface in the worlds of credit cards.
                  If you're interested in learning more please visit the How It
                  Works / FAQ page.
                </p>
                <p>
                  Finally, this site only works with your help. I started this
                  project by reading thousands of credit card forums to collect
                  data points. If you've found value in what you've seen here
                  please consider posting your results in the future.
                </p>
                <p>Max (Founder)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
