import React from 'react';

const Contact = () => {

    return(
       <div>
           <h1>We don't have a contact page... yet</h1>
       </div>
    );
}
export default Contact;